import { ApplicationConfig, ErrorHandler, inject, isDevMode } from '@angular/core';
import {
  NavigationError,
  provideRouter,
  Router,
  withInMemoryScrolling,
  withNavigationErrorHandler,
  withRouterConfig,
} from '@angular/router';

import { routes } from './app.routes';
import {
  provideClientHydration,
  withIncrementalHydration,
} from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideTransloco, translocoConfig } from '@jsverse/transloco';
import { TranslocoHttpLoader } from './transloco-http-loader';
import {
  APP_INITIALIZER_PROVIDER,
  LOCALE_AND_URL_INITIALIZER_PROVIDER,
} from './providers/app-initializer.provider';
import { provideSvgIconsConfig } from '@ngneat/svg-icon';
import * as appIcons from '../assets/_svg-transpiled';
import {
  IS_BROWSER_PROVIDER,
  IS_SERVER_PROVIDER,
} from './providers/is-platform.provider';
import { provideHttpCache, withHttpCacheInterceptor } from '@ngneat/cashew';
import * as Sentry from '@sentry/angular';
import { BROWSER_LANGUAGE_PROVIDER } from './providers/browser-language.provider';
import { LocalStorageService } from './services/local-storage.service';
import { WINDOW_PROVIDER } from './providers/window.provider';
import { tokenRefresherInterceptor } from './interceptors/token-refresher.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      withNavigationErrorHandler(navigationErrorHandler),
    ),
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    LOCALE_AND_URL_INITIALIZER_PROVIDER,
    APP_INITIALIZER_PROVIDER,
    IS_BROWSER_PROVIDER,
    IS_SERVER_PROVIDER,
    BROWSER_LANGUAGE_PROVIDER,
    WINDOW_PROVIDER,
    provideClientHydration(withIncrementalHydration()),
    provideHttpClient(
      withFetch(),
      withInterceptors([tokenRefresherInterceptor, withHttpCacheInterceptor()]),
    ),
    provideHttpCache(),
    provideSvgIconsConfig({
      sizes: {
        sm: '20px',
        md: '24px',
        lg: '28px',
      },
      defaultSize: 'md',
      icons: Object.values(appIcons),
    }),
    provideTransloco({
      config: translocoConfig({
        availableLangs: ['en', 'cs', 'sk'],
        defaultLang: 'en',
        fallbackLang: 'en',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      }),
      loader: TranslocoHttpLoader,
    }),
  ],
};

function createErrorHandler(): ErrorHandler {
  const sentryErrorHandler = Sentry.createErrorHandler({
    showDialog: false,
  });

  return {
    handleError(error: unknown) {
      try {
        if (error instanceof Error && error.message.includes('Luigis', 0)) {
          console.log(error);
        }

        sentryErrorHandler.handleError(error);
      } catch (e) {
        console.log({ e });
      }
    },
  };
}

function navigationErrorHandler(e: NavigationError): void {
  const localStorage = inject(LocalStorageService);

  if (localStorage.type === 'memory' || !isChunkLoadingError(e.error)) {
    throw e;
  }

  const count =
    1 + Number.parseInt(localStorage.getItem('chunkLoadErrorReloadCount') ?? '0');

  localStorage.setItem('chunkLoadErrorReloadTarget', e.url);
  localStorage.setItem('chunkLoadErrorReloadCount', count.toString());

  window.location.reload();
}

function isChunkLoadingError(e: unknown): boolean {
  const chunkFailedMessage = /Loading chunk [\d]+ failed/;
  const failedToFetchMessage = 'Failed to fetch dynamically imported module';

  if (
    typeof e === 'string' &&
    (e.startsWith(failedToFetchMessage) || chunkFailedMessage.test(e))
  ) {
    return true;
  }

  if (e instanceof Error) {
    return (
      e.message.startsWith(failedToFetchMessage) || chunkFailedMessage.test(e.message)
    );
  }

  return false;
}
